@import url(https://fonts.googleapis.com/css?family=Muli:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,400i,500,600,700&display=swap);
* {
  font-family: 'Poppins';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

/* Uncomment and set these variables to customize the grid. */

.container-fluid {
  margin-right: auto;
  margin-left: auto;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.col-xs-2 {
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-0 {
  margin-left: 8.333%;
}

.col-xs-offset-1 {
  margin-left: 8.333%;
}

.col-xs-offset-2 {
  margin-left: 16.667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333%;
}

.col-xs-offset-5 {
  margin-left: 41.667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333%;
}

.col-xs-offset-8 {
  margin-left: 66.667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333%;
}

.col-xs-offset-11 {
  margin-left: 91.667%;
}

.col-xs-offset-r0 {
  margin-right: 0%;
}

.col-xs-offset-r1 {
  margin-right: 8.333%;
}

.col-xs-offset-r2 {
  margin-right: 16.667%;
}

.col-xs-offset-r3 {
  margin-right: 25%;
}

.col-xs-offset-r4 {
  margin-right: 33.333%;
}

.col-xs-offset-r5 {
  margin-right: 41.667%;
}

.col-xs-offset-r6 {
  margin-right: 50%;
}

.col-xs-offset-r7 {
  margin-right: 58.333%;
}

.col-xs-offset-r8 {
  margin-right: 66.667%;
}

.col-xs-offset-r9 {
  margin-right: 75%;
}

.col-xs-offset-r10 {
  margin-right: 83.333%;
}

.col-xs-offset-r11 {
  margin-right: 91.667%;
}

.start-xs {
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  justify-content: center;
  text-align: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    display: flex;
    box-sizing: border-box;
    flex: 0 0 auto;
  }

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-sm-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-sm-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-sm-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-sm-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.667%;
  }

  .col-sm-offset-r0 {
    margin-right: 0%;
  }

  .col-sm-offset-r1 {
    margin-right: 8.333%;
  }

  .col-sm-offset-r2 {
    margin-right: 16.667%;
  }

  .col-sm-offset-r3 {
    margin-right: 25%;
  }

  .col-sm-offset-r4 {
    margin-right: 33.333%;
  }

  .col-sm-offset-r5 {
    margin-right: 41.667%;
  }

  .col-sm-offset-r6 {
    margin-right: 50%;
  }

  .col-sm-offset-r7 {
    margin-right: 58.333%;
  }

  .col-sm-offset-r8 {
    margin-right: 66.667%;
  }

  .col-sm-offset-r9 {
    margin-right: 75%;
  }

  .col-sm-offset-r10 {
    margin-right: 83.333%;
  }

  .col-sm-offset-r11 {
    margin-right: 91.667%;
  }

  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    display: flex;
    box-sizing: border-box;
    flex: 0 0 auto;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-md-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-md-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-md-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-md-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.333%;
  }

  .col-md-offset-2 {
    margin-left: 16.667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.333%;
  }

  .col-md-offset-5 {
    margin-left: 41.667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.333%;
  }

  .col-md-offset-8 {
    margin-left: 66.667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.333%;
  }

  .col-md-offset-11 {
    margin-left: 91.667%;
  }

  .col-md-offset-r0 {
    margin-right: 0%;
  }

  .col-md-offset-r1 {
    margin-right: 8.333%;
  }

  .col-md-offset-r2 {
    margin-right: 16.667%;
  }

  .col-md-offset-r3 {
    margin-right: 25%;
  }

  .col-md-offset-r4 {
    margin-right: 33.333%;
  }

  .col-md-offset-r5 {
    margin-right: 41.667%;
  }

  .col-md-offset-r6 {
    margin-right: 50%;
  }

  .col-md-offset-r7 {
    margin-right: 58.333%;
  }

  .col-md-offset-r8 {
    margin-right: 66.667%;
  }

  .col-md-offset-r9 {
    margin-right: 75%;
  }

  .col-md-offset-r10 {
    margin-right: 83.333%;
  }

  .col-md-offset-r11 {
    margin-right: 91.667%;
  }

  .start-md {
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  .end-md {
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 71rem;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    display: flex;
    box-sizing: border-box;
    flex: 0 0 auto;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-lg-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-lg-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-lg-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-lg-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.667%;
  }

  .col-lg-offset-r0 {
    margin-right: 0%;
  }

  .col-lg-offset-r1 {
    margin-right: 8.333%;
  }

  .col-lg-offset-r2 {
    margin-right: 16.667%;
  }

  .col-lg-offset-r3 {
    margin-right: 25%;
  }

  .col-lg-offset-r4 {
    margin-right: 33.333%;
  }

  .col-lg-offset-r5 {
    margin-right: 41.667%;
  }

  .col-lg-offset-r6 {
    margin-right: 50%;
  }

  .col-lg-offset-r7 {
    margin-right: 58.333%;
  }

  .col-lg-offset-r8 {
    margin-right: 66.667%;
  }

  .col-lg-offset-r9 {
    margin-right: 75%;
  }

  .col-lg-offset-r10 {
    margin-right: 83.333%;
  }

  .col-lg-offset-r11 {
    margin-right: 91.667%;
  }

  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

:root {
  --basic-margin-unit: 5px;
}

.p-1 {
  padding: calc(5px * 1);
  padding: calc(var(--basic-margin-unit) * 1);
}
.p-2 {
  padding: calc(5px * 2);
  padding: calc(var(--basic-margin-unit) * 2);
}
.p-3 {
  padding: calc(5px * 3);
  padding: calc(var(--basic-margin-unit) * 3);
}
.p-4 {
  padding: calc(5px * 4);
  padding: calc(var(--basic-margin-unit) * 4);
}
.p-5 {
  padding: calc(5px * 5);
  padding: calc(var(--basic-margin-unit) * 5);
}
.p-6 {
  padding: calc(5px * 6);
  padding: calc(var(--basic-margin-unit) * 6);
}
.p-7 {
  padding: calc(5px * 7);
  padding: calc(var(--basic-margin-unit) * 7);
}
.p-8 {
  padding: calc(5px * 8);
  padding: calc(var(--basic-margin-unit) * 8);
}
.p-9 {
  padding: calc(5px * 9);
  padding: calc(var(--basic-margin-unit) * 9);
}
.p-10 {
  padding: calc(5px * 10);
  padding: calc(var(--basic-margin-unit) * 10);
}

.pr-10p {
  padding-right: 10%;
}
.pr-20p {
  padding-right: 20%;
}
.pr-30p {
  padding-right: 30%;
}
.pr-40p {
  padding-right: 40%;
}
.pr-50p {
  padding-right: 50%;
}
.pr-60p {
  padding-right: 60%;
}
.pr-70p {
  padding-right: 70%;
}
.pr-80p {
  padding-right: 80%;
}
.pr-90p {
  padding-right: 90%;
}

.pl-10p {
  padding-left: 10%;
}
.pl-20p {
  padding-left: 20%;
}
.pl-30p {
  padding-left: 30%;
}
.pl-40p {
  padding-left: 40%;
}
.pl-50p {
  padding-left: 50%;
}
.pl-60p {
  padding-left: 60%;
}
.pl-70p {
  padding-left: 70%;
}
.pl-80p {
  padding-left: 80%;
}
.pl-90p {
  padding-left: 90%;
}

.pt-1 {
  padding-top: calc(5px * 1);
  padding-top: calc(var(--basic-margin-unit) * 1);
}
.pt-2 {
  padding-top: calc(5px * 2);
  padding-top: calc(var(--basic-margin-unit) * 2);
}
.pt-3 {
  padding-top: calc(5px * 3);
  padding-top: calc(var(--basic-margin-unit) * 3);
}
.pt-4 {
  padding-top: calc(5px * 4);
  padding-top: calc(var(--basic-margin-unit) * 4);
}
.pt-5 {
  padding-top: calc(5px * 5);
  padding-top: calc(var(--basic-margin-unit) * 5);
}
.pt-6 {
  padding-top: calc(5px * 6);
  padding-top: calc(var(--basic-margin-unit) * 6);
}
.pt-7 {
  padding-top: calc(5px * 7);
  padding-top: calc(var(--basic-margin-unit) * 7);
}
.pt-8 {
  padding-top: calc(5px * 8);
  padding-top: calc(var(--basic-margin-unit) * 8);
}
.pt-9 {
  padding-top: calc(5px * 9);
  padding-top: calc(var(--basic-margin-unit) * 9);
}
.pt-10 {
  padding-top: calc(5px * 10);
  padding-top: calc(var(--basic-margin-unit) * 10);
}

.pr-1 {
  padding-right: calc(5px * 1);
  padding-right: calc(var(--basic-margin-unit) * 1);
}
.pr-2 {
  padding-right: calc(5px * 2);
  padding-right: calc(var(--basic-margin-unit) * 2);
}
.pr-3 {
  padding-right: calc(5px * 3);
  padding-right: calc(var(--basic-margin-unit) * 3);
}
.pr-4 {
  padding-right: calc(5px * 4);
  padding-right: calc(var(--basic-margin-unit) * 4);
}
.pr-5 {
  padding-right: calc(5px * 5);
  padding-right: calc(var(--basic-margin-unit) * 5);
}
.pr-6 {
  padding-right: calc(5px * 6);
  padding-right: calc(var(--basic-margin-unit) * 6);
}
.pr-7 {
  padding-right: calc(5px * 7);
  padding-right: calc(var(--basic-margin-unit) * 7);
}
.pr-8 {
  padding-right: calc(5px * 8);
  padding-right: calc(var(--basic-margin-unit) * 8);
}
.pr-9 {
  padding-right: calc(5px * 9);
  padding-right: calc(var(--basic-margin-unit) * 9);
}
.pr-10 {
  padding-right: calc(5px * 10);
  padding-right: calc(var(--basic-margin-unit) * 10);
}

.pl-1 {
  padding-left: calc(5px * 1);
  padding-left: calc(var(--basic-margin-unit) * 1);
}
.pl-2 {
  padding-left: calc(5px * 2);
  padding-left: calc(var(--basic-margin-unit) * 2);
}
.pl-3 {
  padding-left: calc(5px * 3);
  padding-left: calc(var(--basic-margin-unit) * 3);
}
.pl-4 {
  padding-left: calc(5px * 4);
  padding-left: calc(var(--basic-margin-unit) * 4);
}
.pl-5 {
  padding-left: calc(5px * 5);
  padding-left: calc(var(--basic-margin-unit) * 5);
}
.pl-6 {
  padding-left: calc(5px * 6);
  padding-left: calc(var(--basic-margin-unit) * 6);
}
.pl-7 {
  padding-left: calc(5px * 7);
  padding-left: calc(var(--basic-margin-unit) * 7);
}
.pl-8 {
  padding-left: calc(5px * 8);
  padding-left: calc(var(--basic-margin-unit) * 8);
}
.pl-9 {
  padding-left: calc(5px * 9);
  padding-left: calc(var(--basic-margin-unit) * 9);
}
.pl-10 {
  padding-left: calc(5px * 10);
  padding-left: calc(var(--basic-margin-unit) * 10);
}

.pb-1 {
  padding-bottom: calc(5px * 1);
  padding-bottom: calc(var(--basic-margin-unit) * 1);
}
.pb-2 {
  padding-bottom: calc(5px * 2);
  padding-bottom: calc(var(--basic-margin-unit) * 2);
}
.pb-3 {
  padding-bottom: calc(5px * 3);
  padding-bottom: calc(var(--basic-margin-unit) * 3);
}
.pb-4 {
  padding-bottom: calc(5px * 4);
  padding-bottom: calc(var(--basic-margin-unit) * 4);
}
.pb-5 {
  padding-bottom: calc(5px * 5);
  padding-bottom: calc(var(--basic-margin-unit) * 5);
}
.pb-6 {
  padding-bottom: calc(5px * 6);
  padding-bottom: calc(var(--basic-margin-unit) * 6);
}
.pb-7 {
  padding-bottom: calc(5px * 7);
  padding-bottom: calc(var(--basic-margin-unit) * 7);
}
.pb-8 {
  padding-bottom: calc(5px * 8);
  padding-bottom: calc(var(--basic-margin-unit) * 8);
}
.pb-9 {
  padding-bottom: calc(5px * 9);
  padding-bottom: calc(var(--basic-margin-unit) * 9);
}
.pb-10 {
  padding-bottom: calc(5px * 10);
  padding-bottom: calc(var(--basic-margin-unit) * 10);
}

.m-1 {
  margin: calc(5px * 1);
  margin: calc(var(--basic-margin-unit) * 1);
}
.m-2 {
  margin: calc(5px * 2);
  margin: calc(var(--basic-margin-unit) * 2);
}
.m-3 {
  margin: calc(5px * 3);
  margin: calc(var(--basic-margin-unit) * 3);
}
.m-4 {
  margin: calc(5px * 4);
  margin: calc(var(--basic-margin-unit) * 4);
}
.m-5 {
  margin: calc(5px * 5);
  margin: calc(var(--basic-margin-unit) * 5);
}
.m-6 {
  margin: calc(5px * 6);
  margin: calc(var(--basic-margin-unit) * 6);
}
.m-7 {
  margin: calc(5px * 7);
  margin: calc(var(--basic-margin-unit) * 7);
}
.m-8 {
  margin: calc(5px * 8);
  margin: calc(var(--basic-margin-unit) * 8);
}
.m-9 {
  margin: calc(5px * 9);
  margin: calc(var(--basic-margin-unit) * 9);
}
.m-10 {
  margin: calc(5px * 10);
  margin: calc(var(--basic-margin-unit) * 10);
}

.mr-10p {
  margin-right: 10%;
}
.mr-20p {
  margin-right: 20%;
}
.mr-30p {
  margin-right: 30%;
}
.mr-40p {
  margin-right: 40%;
}
.mr-50p {
  margin-right: 50%;
}
.mr-60p {
  margin-right: 60%;
}
.mr-70p {
  margin-right: 70%;
}
.mr-80p {
  margin-right: 80%;
}
.mr-90p {
  margin-right: 90%;
}

.ml-10p {
  margin-left: 10%;
}
.ml-20p {
  margin-left: 20%;
}
.ml-30p {
  margin-left: 30%;
}
.ml-40p {
  margin-left: 40%;
}
.ml-50p {
  margin-left: 50%;
}
.ml-60p {
  margin-left: 60%;
}
.ml-70p {
  margin-left: 70%;
}
.ml-80p {
  margin-left: 80%;
}
.ml-90p {
  margin-left: 90%;
}

.mt-1 {
  margin-top: calc(5px * 1);
  margin-top: calc(var(--basic-margin-unit) * 1);
}
.mt-2 {
  margin-top: calc(5px * 2);
  margin-top: calc(var(--basic-margin-unit) * 2);
}
.mt-3 {
  margin-top: calc(5px * 3);
  margin-top: calc(var(--basic-margin-unit) * 3);
}
.mt-4 {
  margin-top: calc(5px * 4);
  margin-top: calc(var(--basic-margin-unit) * 4);
}
.mt-5 {
  margin-top: calc(5px * 5);
  margin-top: calc(var(--basic-margin-unit) * 5);
}
.mt-6 {
  margin-top: calc(5px * 6);
  margin-top: calc(var(--basic-margin-unit) * 6);
}
.mt-7 {
  margin-top: calc(5px * 7);
  margin-top: calc(var(--basic-margin-unit) * 7);
}
.mt-8 {
  margin-top: calc(5px * 8);
  margin-top: calc(var(--basic-margin-unit) * 8);
}
.mt-9 {
  margin-top: calc(5px * 9);
  margin-top: calc(var(--basic-margin-unit) * 9);
}
.mt-10 {
  margin-top: calc(5px * 10);
  margin-top: calc(var(--basic-margin-unit) * 10);
}

.mr-1 {
  margin-right: calc(5px * 1);
  margin-right: calc(var(--basic-margin-unit) * 1);
}
.mr-2 {
  margin-right: calc(5px * 2);
  margin-right: calc(var(--basic-margin-unit) * 2);
}
.mr-3 {
  margin-right: calc(5px * 3);
  margin-right: calc(var(--basic-margin-unit) * 3);
}
.mr-4 {
  margin-right: calc(5px * 4);
  margin-right: calc(var(--basic-margin-unit) * 4);
}
.mr-5 {
  margin-right: calc(5px * 5);
  margin-right: calc(var(--basic-margin-unit) * 5);
}
.mr-6 {
  margin-right: calc(5px * 6);
  margin-right: calc(var(--basic-margin-unit) * 6);
}
.mr-7 {
  margin-right: calc(5px * 7);
  margin-right: calc(var(--basic-margin-unit) * 7);
}
.mr-8 {
  margin-right: calc(5px * 8);
  margin-right: calc(var(--basic-margin-unit) * 8);
}
.mr-9 {
  margin-right: calc(5px * 9);
  margin-right: calc(var(--basic-margin-unit) * 9);
}
.mr-10 {
  margin-right: calc(5px * 10);
  margin-right: calc(var(--basic-margin-unit) * 10);
}

.ml-1 {
  margin-left: calc(5px * 1);
  margin-left: calc(var(--basic-margin-unit) * 1);
}
.ml-2 {
  margin-left: calc(5px * 2);
  margin-left: calc(var(--basic-margin-unit) * 2);
}
.ml-3 {
  margin-left: calc(5px * 3);
  margin-left: calc(var(--basic-margin-unit) * 3);
}
.ml-4 {
  margin-left: calc(5px * 4);
  margin-left: calc(var(--basic-margin-unit) * 4);
}
.ml-5 {
  margin-left: calc(5px * 5);
  margin-left: calc(var(--basic-margin-unit) * 5);
}
.ml-6 {
  margin-left: calc(5px * 6);
  margin-left: calc(var(--basic-margin-unit) * 6);
}
.ml-7 {
  margin-left: calc(5px * 7);
  margin-left: calc(var(--basic-margin-unit) * 7);
}
.ml-8 {
  margin-left: calc(5px * 8);
  margin-left: calc(var(--basic-margin-unit) * 8);
}
.ml-9 {
  margin-left: calc(5px * 9);
  margin-left: calc(var(--basic-margin-unit) * 9);
}
.ml-10 {
  margin-left: calc(5px * 10);
  margin-left: calc(var(--basic-margin-unit) * 10);
}

.mb-1 {
  margin-bottom: calc(5px * 1);
  margin-bottom: calc(var(--basic-margin-unit) * 1);
}
.mb-2 {
  margin-bottom: calc(5px * 2);
  margin-bottom: calc(var(--basic-margin-unit) * 2);
}
.mb-3 {
  margin-bottom: calc(5px * 3);
  margin-bottom: calc(var(--basic-margin-unit) * 3);
}
.mb-4 {
  margin-bottom: calc(5px * 4);
  margin-bottom: calc(var(--basic-margin-unit) * 4);
}
.mb-5 {
  margin-bottom: calc(5px * 5);
  margin-bottom: calc(var(--basic-margin-unit) * 5);
}
.mb-6 {
  margin-bottom: calc(5px * 6);
  margin-bottom: calc(var(--basic-margin-unit) * 6);
}
.mb-7 {
  margin-bottom: calc(5px * 7);
  margin-bottom: calc(var(--basic-margin-unit) * 7);
}
.mb-8 {
  margin-bottom: calc(5px * 8);
  margin-bottom: calc(var(--basic-margin-unit) * 8);
}
.mb-9 {
  margin-bottom: calc(5px * 9);
  margin-bottom: calc(var(--basic-margin-unit) * 9);
}
.mb-10 {
  margin-bottom: calc(5px * 10);
  margin-bottom: calc(var(--basic-margin-unit) * 10);
}

@media only screen and (max-width: 48em) {
  .p-xs-0 {
    padding: 0;
  }
  .p-xs-1 {
    padding: calc(5px * 1);
    padding: calc(var(--basic-margin-unit) * 1);
  }
  .p-xs-2 {
    padding: calc(5px * 2);
    padding: calc(var(--basic-margin-unit) * 2);
  }
  .p-xs-3 {
    padding: calc(5px * 3);
    padding: calc(var(--basic-margin-unit) * 3);
  }
  .p-xs-4 {
    padding: calc(5px * 4);
    padding: calc(var(--basic-margin-unit) * 4);
  }
  .p-xs-5 {
    padding: calc(5px * 5);
    padding: calc(var(--basic-margin-unit) * 5);
  }
  .p-xs-6 {
    padding: calc(5px * 6);
    padding: calc(var(--basic-margin-unit) * 6);
  }
  .p-xs-7 {
    padding: calc(5px * 7);
    padding: calc(var(--basic-margin-unit) * 7);
  }
  .p-xs-8 {
    padding: calc(5px * 8);
    padding: calc(var(--basic-margin-unit) * 8);
  }
  .p-xs-9 {
    padding: calc(5px * 9);
    padding: calc(var(--basic-margin-unit) * 9);
  }
  .p-xs-10 {
    padding: calc(5px * 10);
    padding: calc(var(--basic-margin-unit) * 10);
  }

  .pr-xs-10p {
    padding-right: 10%;
  }
  .pr-xs-20p {
    padding-right: 20%;
  }
  .pr-xs-30p {
    padding-right: 30%;
  }
  .pr-xs-40p {
    padding-right: 40%;
  }
  .pr-xs-50p {
    padding-right: 50%;
  }
  .pr-xs-60p {
    padding-right: 60%;
  }
  .pr-xs-70p {
    padding-right: 70%;
  }
  .pr-xs-80p {
    padding-right: 80%;
  }
  .pr-xs-90p {
    padding-right: 90%;
  }

  .pl-xs-10p {
    padding-left: 10%;
  }
  .pl-xs-20p {
    padding-left: 20%;
  }
  .pl-xs-30p {
    padding-left: 30%;
  }
  .pl-xs-40p {
    padding-left: 40%;
  }
  .pl-xs-50p {
    padding-left: 50%;
  }
  .pl-xs-60p {
    padding-left: 60%;
  }
  .pl-xs-70p {
    padding-left: 70%;
  }
  .pl-xs-80p {
    padding-left: 80%;
  }
  .pl-xs-90p {
    padding-left: 90%;
  }

  .pt-xs-0 {
    padding-top: 0;
  }
  .pt-xs-1 {
    padding-top: calc(5px * 1);
    padding-top: calc(var(--basic-margin-unit) * 1);
  }
  .pt-xs-2 {
    padding-top: calc(5px * 2);
    padding-top: calc(var(--basic-margin-unit) * 2);
  }
  .pt-xs-3 {
    padding-top: calc(5px * 3);
    padding-top: calc(var(--basic-margin-unit) * 3);
  }
  .pt-xs-4 {
    padding-top: calc(5px * 4);
    padding-top: calc(var(--basic-margin-unit) * 4);
  }
  .pt-xs-5 {
    padding-top: calc(5px * 5);
    padding-top: calc(var(--basic-margin-unit) * 5);
  }
  .pt-xs-6 {
    padding-top: calc(5px * 6);
    padding-top: calc(var(--basic-margin-unit) * 6);
  }
  .pt-xs-7 {
    padding-top: calc(5px * 7);
    padding-top: calc(var(--basic-margin-unit) * 7);
  }
  .pt-xs-8 {
    padding-top: calc(5px * 8);
    padding-top: calc(var(--basic-margin-unit) * 8);
  }
  .pt-xs-9 {
    padding-top: calc(5px * 9);
    padding-top: calc(var(--basic-margin-unit) * 9);
  }
  .pt-xs-10 {
    padding-top: calc(5px * 10);
    padding-top: calc(var(--basic-margin-unit) * 10);
  }

  .pr-xs-0 {
    padding-right: 0;
  }
  .pr-xs-1 {
    padding-right: calc(5px * 1);
    padding-right: calc(var(--basic-margin-unit) * 1);
  }
  .pr-xs-2 {
    padding-right: calc(5px * 2);
    padding-right: calc(var(--basic-margin-unit) * 2);
  }
  .pr-xs-3 {
    padding-right: calc(5px * 3);
    padding-right: calc(var(--basic-margin-unit) * 3);
  }
  .pr-xs-4 {
    padding-right: calc(5px * 4);
    padding-right: calc(var(--basic-margin-unit) * 4);
  }
  .pr-xs-5 {
    padding-right: calc(5px * 5);
    padding-right: calc(var(--basic-margin-unit) * 5);
  }
  .pr-xs-6 {
    padding-right: calc(5px * 6);
    padding-right: calc(var(--basic-margin-unit) * 6);
  }
  .pr-xs-7 {
    padding-right: calc(5px * 7);
    padding-right: calc(var(--basic-margin-unit) * 7);
  }
  .pr-xs-8 {
    padding-right: calc(5px * 8);
    padding-right: calc(var(--basic-margin-unit) * 8);
  }
  .pr-xs-9 {
    padding-right: calc(5px * 9);
    padding-right: calc(var(--basic-margin-unit) * 9);
  }
  .pr-xs-10 {
    padding-right: calc(5px * 10);
    padding-right: calc(var(--basic-margin-unit) * 10);
  }

  .pl-xs-0 {
    padding-left: 0;
  }
  .pl-xs-1 {
    padding-left: calc(5px * 1);
    padding-left: calc(var(--basic-margin-unit) * 1);
  }
  .pl-xs-2 {
    padding-left: calc(5px * 2);
    padding-left: calc(var(--basic-margin-unit) * 2);
  }
  .pl-xs-3 {
    padding-left: calc(5px * 3);
    padding-left: calc(var(--basic-margin-unit) * 3);
  }
  .pl-xs-4 {
    padding-left: calc(5px * 4);
    padding-left: calc(var(--basic-margin-unit) * 4);
  }
  .pl-xs-5 {
    padding-left: calc(5px * 5);
    padding-left: calc(var(--basic-margin-unit) * 5);
  }
  .pl-xs-6 {
    padding-left: calc(5px * 6);
    padding-left: calc(var(--basic-margin-unit) * 6);
  }
  .pl-xs-7 {
    padding-left: calc(5px * 7);
    padding-left: calc(var(--basic-margin-unit) * 7);
  }
  .pl-xs-8 {
    padding-left: calc(5px * 8);
    padding-left: calc(var(--basic-margin-unit) * 8);
  }
  .pl-xs-9 {
    padding-left: calc(5px * 9);
    padding-left: calc(var(--basic-margin-unit) * 9);
  }
  .pl-xs-10 {
    padding-left: calc(5px * 10);
    padding-left: calc(var(--basic-margin-unit) * 10);
  }

  .pb-xs-0 {
    padding-bottom: 0;
  }
  .pb-xs-1 {
    padding-bottom: calc(5px * 1);
    padding-bottom: calc(var(--basic-margin-unit) * 1);
  }
  .pb-xs-2 {
    padding-bottom: calc(5px * 2);
    padding-bottom: calc(var(--basic-margin-unit) * 2);
  }
  .pb-xs-3 {
    padding-bottom: calc(5px * 3);
    padding-bottom: calc(var(--basic-margin-unit) * 3);
  }
  .pb-xs-4 {
    padding-bottom: calc(5px * 4);
    padding-bottom: calc(var(--basic-margin-unit) * 4);
  }
  .pb-xs-5 {
    padding-bottom: calc(5px * 5);
    padding-bottom: calc(var(--basic-margin-unit) * 5);
  }
  .pb-xs-6 {
    padding-bottom: calc(5px * 6);
    padding-bottom: calc(var(--basic-margin-unit) * 6);
  }
  .pb-xs-7 {
    padding-bottom: calc(5px * 7);
    padding-bottom: calc(var(--basic-margin-unit) * 7);
  }
  .pb-xs-8 {
    padding-bottom: calc(5px * 8);
    padding-bottom: calc(var(--basic-margin-unit) * 8);
  }
  .pb-xs-9 {
    padding-bottom: calc(5px * 9);
    padding-bottom: calc(var(--basic-margin-unit) * 9);
  }
  .pb-xs-10 {
    padding-bottom: calc(5px * 10);
    padding-bottom: calc(var(--basic-margin-unit) * 10);
  }

  .m-xs-0 {
    margin: 0;
  }
  .m-xs-1 {
    margin: calc(5px * 1);
    margin: calc(var(--basic-margin-unit) * 1);
  }
  .m-xs-2 {
    margin: calc(5px * 2);
    margin: calc(var(--basic-margin-unit) * 2);
  }
  .m-xs-3 {
    margin: calc(5px * 3);
    margin: calc(var(--basic-margin-unit) * 3);
  }
  .m-xs-4 {
    margin: calc(5px * 4);
    margin: calc(var(--basic-margin-unit) * 4);
  }
  .m-xs-5 {
    margin: calc(5px * 5);
    margin: calc(var(--basic-margin-unit) * 5);
  }
  .m-xs-6 {
    margin: calc(5px * 6);
    margin: calc(var(--basic-margin-unit) * 6);
  }
  .m-xs-7 {
    margin: calc(5px * 7);
    margin: calc(var(--basic-margin-unit) * 7);
  }
  .m-xs-8 {
    margin: calc(5px * 8);
    margin: calc(var(--basic-margin-unit) * 8);
  }
  .m-xs-9 {
    margin: calc(5px * 9);
    margin: calc(var(--basic-margin-unit) * 9);
  }
  .m-xs-10 {
    margin: calc(5px * 10);
    margin: calc(var(--basic-margin-unit) * 10);
  }

  .mr-xs-10p {
    margin-right: 10%;
  }
  .mr-xs-20p {
    margin-right: 20%;
  }
  .mr-xs-30p {
    margin-right: 30%;
  }
  .mr-xs-40p {
    margin-right: 40%;
  }
  .mr-xs-50p {
    margin-right: 50%;
  }
  .mr-xs-60p {
    margin-right: 60%;
  }
  .mr-xs-70p {
    margin-right: 70%;
  }
  .mr-xs-80p {
    margin-right: 80%;
  }
  .mr-xs-90p {
    margin-right: 90%;
  }

  .ml-xs-10p {
    margin-left: 10%;
  }
  .ml-xs-20p {
    margin-left: 20%;
  }
  .ml-xs-30p {
    margin-left: 30%;
  }
  .ml-xs-40p {
    margin-left: 40%;
  }
  .ml-xs-50p {
    margin-left: 50%;
  }
  .ml-xs-60p {
    margin-left: 60%;
  }
  .ml-xs-70p {
    margin-left: 70%;
  }
  .ml-xs-80p {
    margin-left: 80%;
  }
  .ml-xs-90p {
    margin-left: 90%;
  }

  .mt-xs-0 {
    margin-top: 0;
  }
  .mt-xs-1 {
    margin-top: calc(5px * 1);
    margin-top: calc(var(--basic-margin-unit) * 1);
  }
  .mt-xs-2 {
    margin-top: calc(5px * 2);
    margin-top: calc(var(--basic-margin-unit) * 2);
  }
  .mt-xs-3 {
    margin-top: calc(5px * 3);
    margin-top: calc(var(--basic-margin-unit) * 3);
  }
  .mt-xs-4 {
    margin-top: calc(5px * 4);
    margin-top: calc(var(--basic-margin-unit) * 4);
  }
  .mt-xs-5 {
    margin-top: calc(5px * 5);
    margin-top: calc(var(--basic-margin-unit) * 5);
  }
  .mt-xs-6 {
    margin-top: calc(5px * 6);
    margin-top: calc(var(--basic-margin-unit) * 6);
  }
  .mt-xs-7 {
    margin-top: calc(5px * 7);
    margin-top: calc(var(--basic-margin-unit) * 7);
  }
  .mt-xs-8 {
    margin-top: calc(5px * 8);
    margin-top: calc(var(--basic-margin-unit) * 8);
  }
  .mt-xs-9 {
    margin-top: calc(5px * 9);
    margin-top: calc(var(--basic-margin-unit) * 9);
  }
  .mt-xs-10 {
    margin-top: calc(5px * 10);
    margin-top: calc(var(--basic-margin-unit) * 10);
  }

  .mr-xs-0 {
    margin-right: 0;
  }
  .mr-xs-1 {
    margin-right: calc(5px * 1);
    margin-right: calc(var(--basic-margin-unit) * 1);
  }
  .mr-xs-2 {
    margin-right: calc(5px * 2);
    margin-right: calc(var(--basic-margin-unit) * 2);
  }
  .mr-xs-3 {
    margin-right: calc(5px * 3);
    margin-right: calc(var(--basic-margin-unit) * 3);
  }
  .mr-xs-4 {
    margin-right: calc(5px * 4);
    margin-right: calc(var(--basic-margin-unit) * 4);
  }
  .mr-xs-5 {
    margin-right: calc(5px * 5);
    margin-right: calc(var(--basic-margin-unit) * 5);
  }
  .mr-xs-6 {
    margin-right: calc(5px * 6);
    margin-right: calc(var(--basic-margin-unit) * 6);
  }
  .mr-xs-7 {
    margin-right: calc(5px * 7);
    margin-right: calc(var(--basic-margin-unit) * 7);
  }
  .mr-xs-8 {
    margin-right: calc(5px * 8);
    margin-right: calc(var(--basic-margin-unit) * 8);
  }
  .mr-xs-9 {
    margin-right: calc(5px * 9);
    margin-right: calc(var(--basic-margin-unit) * 9);
  }
  .mr-xs-10 {
    margin-right: calc(5px * 10);
    margin-right: calc(var(--basic-margin-unit) * 10);
  }

  .ml-xs-0 {
    margin-left: 0;
  }
  .ml-xs-1 {
    margin-left: calc(5px * 1);
    margin-left: calc(var(--basic-margin-unit) * 1);
  }
  .ml-xs-2 {
    margin-left: calc(5px * 2);
    margin-left: calc(var(--basic-margin-unit) * 2);
  }
  .ml-xs-3 {
    margin-left: calc(5px * 3);
    margin-left: calc(var(--basic-margin-unit) * 3);
  }
  .ml-xs-4 {
    margin-left: calc(5px * 4);
    margin-left: calc(var(--basic-margin-unit) * 4);
  }
  .ml-xs-5 {
    margin-left: calc(5px * 5);
    margin-left: calc(var(--basic-margin-unit) * 5);
  }
  .ml-xs-6 {
    margin-left: calc(5px * 6);
    margin-left: calc(var(--basic-margin-unit) * 6);
  }
  .ml-xs-7 {
    margin-left: calc(5px * 7);
    margin-left: calc(var(--basic-margin-unit) * 7);
  }
  .ml-xs-8 {
    margin-left: calc(5px * 8);
    margin-left: calc(var(--basic-margin-unit) * 8);
  }
  .ml-xs-9 {
    margin-left: calc(5px * 9);
    margin-left: calc(var(--basic-margin-unit) * 9);
  }
  .ml-xs-10 {
    margin-left: calc(5px * 10);
    margin-left: calc(var(--basic-margin-unit) * 10);
  }

  .mb-xs-0 {
    margin-bottom: 0;
  }
  .mb-xs-1 {
    margin-bottom: calc(5px * 1);
    margin-bottom: calc(var(--basic-margin-unit) * 1);
  }
  .mb-xs-2 {
    margin-bottom: calc(5px * 2);
    margin-bottom: calc(var(--basic-margin-unit) * 2);
  }
  .mb-xs-3 {
    margin-bottom: calc(5px * 3);
    margin-bottom: calc(var(--basic-margin-unit) * 3);
  }
  .mb-xs-4 {
    margin-bottom: calc(5px * 4);
    margin-bottom: calc(var(--basic-margin-unit) * 4);
  }
  .mb-xs-5 {
    margin-bottom: calc(5px * 5);
    margin-bottom: calc(var(--basic-margin-unit) * 5);
  }
  .mb-xs-6 {
    margin-bottom: calc(5px * 6);
    margin-bottom: calc(var(--basic-margin-unit) * 6);
  }
  .mb-xs-7 {
    margin-bottom: calc(5px * 7);
    margin-bottom: calc(var(--basic-margin-unit) * 7);
  }
  .mb-xs-8 {
    margin-bottom: calc(5px * 8);
    margin-bottom: calc(var(--basic-margin-unit) * 8);
  }
  .mb-xs-9 {
    margin-bottom: calc(5px * 9);
    margin-bottom: calc(var(--basic-margin-unit) * 9);
  }
  .mb-xs-10 {
    margin-bottom: calc(5px * 10);
    margin-bottom: calc(var(--basic-margin-unit) * 10);
  }
}

.styles_container__3Pufb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 20px;
  padding-top: 35px;
  padding-bottom: 20px;
  max-width: 100vw;
}

.styles_section__3wmRK {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.styles_name__2XCoX {
  font-family: 'Muli';
  margin-left: 12px;
  font-size: 19px;
  letter-spacing: 0.4, normal;
  opacity: 0.7;
  font-weight: bold;
}

.styles_link__2OULD {
  font-weight: bold;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 32px;
  text-decoration: none;
  color: #454444;
}

.styles_link__2OULD:hover {
  color: #38599e;
}

.styles_divider__3Xs4k {
  height: 100%;
  width: 1px;
  background-color: #454444;
  margin-right: 17px;
  margin-left: 37px;
}
@media print {
  .styles_container__3Pufb {
    display: none;
    background: #EE5C5C;
  }
}

.styles_container__3ZOac {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  justify-content: flex-start;
  flex-direction: column;
}

.styles_container__3Mk3B {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.styles_dot__9LsIW {
  display: inline-block;
  border-radius: 50%;
  -webkit-animation: styles_bounce__wl-ZB 1s linear infinite;
          animation: styles_bounce__wl-ZB 1s linear infinite;
}

.styles_dot__9LsIW:nth-child(2) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.styles_dot__9LsIW:nth-child(3) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  margin-right: 0 !important;
}

@-webkit-keyframes styles_bounce__wl-ZB {
  0%,
  60%,
  100% {
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }

  30% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes styles_bounce__wl-ZB {
  0%,
  60%,
  100% {
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }

  30% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

