:local(.container) {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

:local(.dot) {
  display: inline-block;
  border-radius: 50%;
  animation: bounce 1s linear infinite;
}

:local(.dot):nth-child(2) {
  animation-delay: -0.8s;
}

:local(.dot):nth-child(3) {
  animation-delay: -0.6s;
  margin-right: 0 !important;
}

@keyframes bounce {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: scale(1.5);
  }
}
