:root {
  --basic-margin-unit: 5px;
}

.p-1 {
  padding: calc(var(--basic-margin-unit) * 1);
}
.p-2 {
  padding: calc(var(--basic-margin-unit) * 2);
}
.p-3 {
  padding: calc(var(--basic-margin-unit) * 3);
}
.p-4 {
  padding: calc(var(--basic-margin-unit) * 4);
}
.p-5 {
  padding: calc(var(--basic-margin-unit) * 5);
}
.p-6 {
  padding: calc(var(--basic-margin-unit) * 6);
}
.p-7 {
  padding: calc(var(--basic-margin-unit) * 7);
}
.p-8 {
  padding: calc(var(--basic-margin-unit) * 8);
}
.p-9 {
  padding: calc(var(--basic-margin-unit) * 9);
}
.p-10 {
  padding: calc(var(--basic-margin-unit) * 10);
}

.pr-10p {
  padding-right: 10%;
}
.pr-20p {
  padding-right: 20%;
}
.pr-30p {
  padding-right: 30%;
}
.pr-40p {
  padding-right: 40%;
}
.pr-50p {
  padding-right: 50%;
}
.pr-60p {
  padding-right: 60%;
}
.pr-70p {
  padding-right: 70%;
}
.pr-80p {
  padding-right: 80%;
}
.pr-90p {
  padding-right: 90%;
}

.pl-10p {
  padding-left: 10%;
}
.pl-20p {
  padding-left: 20%;
}
.pl-30p {
  padding-left: 30%;
}
.pl-40p {
  padding-left: 40%;
}
.pl-50p {
  padding-left: 50%;
}
.pl-60p {
  padding-left: 60%;
}
.pl-70p {
  padding-left: 70%;
}
.pl-80p {
  padding-left: 80%;
}
.pl-90p {
  padding-left: 90%;
}

.pt-1 {
  padding-top: calc(var(--basic-margin-unit) * 1);
}
.pt-2 {
  padding-top: calc(var(--basic-margin-unit) * 2);
}
.pt-3 {
  padding-top: calc(var(--basic-margin-unit) * 3);
}
.pt-4 {
  padding-top: calc(var(--basic-margin-unit) * 4);
}
.pt-5 {
  padding-top: calc(var(--basic-margin-unit) * 5);
}
.pt-6 {
  padding-top: calc(var(--basic-margin-unit) * 6);
}
.pt-7 {
  padding-top: calc(var(--basic-margin-unit) * 7);
}
.pt-8 {
  padding-top: calc(var(--basic-margin-unit) * 8);
}
.pt-9 {
  padding-top: calc(var(--basic-margin-unit) * 9);
}
.pt-10 {
  padding-top: calc(var(--basic-margin-unit) * 10);
}

.pr-1 {
  padding-right: calc(var(--basic-margin-unit) * 1);
}
.pr-2 {
  padding-right: calc(var(--basic-margin-unit) * 2);
}
.pr-3 {
  padding-right: calc(var(--basic-margin-unit) * 3);
}
.pr-4 {
  padding-right: calc(var(--basic-margin-unit) * 4);
}
.pr-5 {
  padding-right: calc(var(--basic-margin-unit) * 5);
}
.pr-6 {
  padding-right: calc(var(--basic-margin-unit) * 6);
}
.pr-7 {
  padding-right: calc(var(--basic-margin-unit) * 7);
}
.pr-8 {
  padding-right: calc(var(--basic-margin-unit) * 8);
}
.pr-9 {
  padding-right: calc(var(--basic-margin-unit) * 9);
}
.pr-10 {
  padding-right: calc(var(--basic-margin-unit) * 10);
}

.pl-1 {
  padding-left: calc(var(--basic-margin-unit) * 1);
}
.pl-2 {
  padding-left: calc(var(--basic-margin-unit) * 2);
}
.pl-3 {
  padding-left: calc(var(--basic-margin-unit) * 3);
}
.pl-4 {
  padding-left: calc(var(--basic-margin-unit) * 4);
}
.pl-5 {
  padding-left: calc(var(--basic-margin-unit) * 5);
}
.pl-6 {
  padding-left: calc(var(--basic-margin-unit) * 6);
}
.pl-7 {
  padding-left: calc(var(--basic-margin-unit) * 7);
}
.pl-8 {
  padding-left: calc(var(--basic-margin-unit) * 8);
}
.pl-9 {
  padding-left: calc(var(--basic-margin-unit) * 9);
}
.pl-10 {
  padding-left: calc(var(--basic-margin-unit) * 10);
}

.pb-1 {
  padding-bottom: calc(var(--basic-margin-unit) * 1);
}
.pb-2 {
  padding-bottom: calc(var(--basic-margin-unit) * 2);
}
.pb-3 {
  padding-bottom: calc(var(--basic-margin-unit) * 3);
}
.pb-4 {
  padding-bottom: calc(var(--basic-margin-unit) * 4);
}
.pb-5 {
  padding-bottom: calc(var(--basic-margin-unit) * 5);
}
.pb-6 {
  padding-bottom: calc(var(--basic-margin-unit) * 6);
}
.pb-7 {
  padding-bottom: calc(var(--basic-margin-unit) * 7);
}
.pb-8 {
  padding-bottom: calc(var(--basic-margin-unit) * 8);
}
.pb-9 {
  padding-bottom: calc(var(--basic-margin-unit) * 9);
}
.pb-10 {
  padding-bottom: calc(var(--basic-margin-unit) * 10);
}

.m-1 {
  margin: calc(var(--basic-margin-unit) * 1);
}
.m-2 {
  margin: calc(var(--basic-margin-unit) * 2);
}
.m-3 {
  margin: calc(var(--basic-margin-unit) * 3);
}
.m-4 {
  margin: calc(var(--basic-margin-unit) * 4);
}
.m-5 {
  margin: calc(var(--basic-margin-unit) * 5);
}
.m-6 {
  margin: calc(var(--basic-margin-unit) * 6);
}
.m-7 {
  margin: calc(var(--basic-margin-unit) * 7);
}
.m-8 {
  margin: calc(var(--basic-margin-unit) * 8);
}
.m-9 {
  margin: calc(var(--basic-margin-unit) * 9);
}
.m-10 {
  margin: calc(var(--basic-margin-unit) * 10);
}

.mr-10p {
  margin-right: 10%;
}
.mr-20p {
  margin-right: 20%;
}
.mr-30p {
  margin-right: 30%;
}
.mr-40p {
  margin-right: 40%;
}
.mr-50p {
  margin-right: 50%;
}
.mr-60p {
  margin-right: 60%;
}
.mr-70p {
  margin-right: 70%;
}
.mr-80p {
  margin-right: 80%;
}
.mr-90p {
  margin-right: 90%;
}

.ml-10p {
  margin-left: 10%;
}
.ml-20p {
  margin-left: 20%;
}
.ml-30p {
  margin-left: 30%;
}
.ml-40p {
  margin-left: 40%;
}
.ml-50p {
  margin-left: 50%;
}
.ml-60p {
  margin-left: 60%;
}
.ml-70p {
  margin-left: 70%;
}
.ml-80p {
  margin-left: 80%;
}
.ml-90p {
  margin-left: 90%;
}

.mt-1 {
  margin-top: calc(var(--basic-margin-unit) * 1);
}
.mt-2 {
  margin-top: calc(var(--basic-margin-unit) * 2);
}
.mt-3 {
  margin-top: calc(var(--basic-margin-unit) * 3);
}
.mt-4 {
  margin-top: calc(var(--basic-margin-unit) * 4);
}
.mt-5 {
  margin-top: calc(var(--basic-margin-unit) * 5);
}
.mt-6 {
  margin-top: calc(var(--basic-margin-unit) * 6);
}
.mt-7 {
  margin-top: calc(var(--basic-margin-unit) * 7);
}
.mt-8 {
  margin-top: calc(var(--basic-margin-unit) * 8);
}
.mt-9 {
  margin-top: calc(var(--basic-margin-unit) * 9);
}
.mt-10 {
  margin-top: calc(var(--basic-margin-unit) * 10);
}

.mr-1 {
  margin-right: calc(var(--basic-margin-unit) * 1);
}
.mr-2 {
  margin-right: calc(var(--basic-margin-unit) * 2);
}
.mr-3 {
  margin-right: calc(var(--basic-margin-unit) * 3);
}
.mr-4 {
  margin-right: calc(var(--basic-margin-unit) * 4);
}
.mr-5 {
  margin-right: calc(var(--basic-margin-unit) * 5);
}
.mr-6 {
  margin-right: calc(var(--basic-margin-unit) * 6);
}
.mr-7 {
  margin-right: calc(var(--basic-margin-unit) * 7);
}
.mr-8 {
  margin-right: calc(var(--basic-margin-unit) * 8);
}
.mr-9 {
  margin-right: calc(var(--basic-margin-unit) * 9);
}
.mr-10 {
  margin-right: calc(var(--basic-margin-unit) * 10);
}

.ml-1 {
  margin-left: calc(var(--basic-margin-unit) * 1);
}
.ml-2 {
  margin-left: calc(var(--basic-margin-unit) * 2);
}
.ml-3 {
  margin-left: calc(var(--basic-margin-unit) * 3);
}
.ml-4 {
  margin-left: calc(var(--basic-margin-unit) * 4);
}
.ml-5 {
  margin-left: calc(var(--basic-margin-unit) * 5);
}
.ml-6 {
  margin-left: calc(var(--basic-margin-unit) * 6);
}
.ml-7 {
  margin-left: calc(var(--basic-margin-unit) * 7);
}
.ml-8 {
  margin-left: calc(var(--basic-margin-unit) * 8);
}
.ml-9 {
  margin-left: calc(var(--basic-margin-unit) * 9);
}
.ml-10 {
  margin-left: calc(var(--basic-margin-unit) * 10);
}

.mb-1 {
  margin-bottom: calc(var(--basic-margin-unit) * 1);
}
.mb-2 {
  margin-bottom: calc(var(--basic-margin-unit) * 2);
}
.mb-3 {
  margin-bottom: calc(var(--basic-margin-unit) * 3);
}
.mb-4 {
  margin-bottom: calc(var(--basic-margin-unit) * 4);
}
.mb-5 {
  margin-bottom: calc(var(--basic-margin-unit) * 5);
}
.mb-6 {
  margin-bottom: calc(var(--basic-margin-unit) * 6);
}
.mb-7 {
  margin-bottom: calc(var(--basic-margin-unit) * 7);
}
.mb-8 {
  margin-bottom: calc(var(--basic-margin-unit) * 8);
}
.mb-9 {
  margin-bottom: calc(var(--basic-margin-unit) * 9);
}
.mb-10 {
  margin-bottom: calc(var(--basic-margin-unit) * 10);
}

@media only screen and (max-width: 48em) {
  .p-xs-0 {
    padding: 0;
  }
  .p-xs-1 {
    padding: calc(var(--basic-margin-unit) * 1);
  }
  .p-xs-2 {
    padding: calc(var(--basic-margin-unit) * 2);
  }
  .p-xs-3 {
    padding: calc(var(--basic-margin-unit) * 3);
  }
  .p-xs-4 {
    padding: calc(var(--basic-margin-unit) * 4);
  }
  .p-xs-5 {
    padding: calc(var(--basic-margin-unit) * 5);
  }
  .p-xs-6 {
    padding: calc(var(--basic-margin-unit) * 6);
  }
  .p-xs-7 {
    padding: calc(var(--basic-margin-unit) * 7);
  }
  .p-xs-8 {
    padding: calc(var(--basic-margin-unit) * 8);
  }
  .p-xs-9 {
    padding: calc(var(--basic-margin-unit) * 9);
  }
  .p-xs-10 {
    padding: calc(var(--basic-margin-unit) * 10);
  }

  .pr-xs-10p {
    padding-right: 10%;
  }
  .pr-xs-20p {
    padding-right: 20%;
  }
  .pr-xs-30p {
    padding-right: 30%;
  }
  .pr-xs-40p {
    padding-right: 40%;
  }
  .pr-xs-50p {
    padding-right: 50%;
  }
  .pr-xs-60p {
    padding-right: 60%;
  }
  .pr-xs-70p {
    padding-right: 70%;
  }
  .pr-xs-80p {
    padding-right: 80%;
  }
  .pr-xs-90p {
    padding-right: 90%;
  }

  .pl-xs-10p {
    padding-left: 10%;
  }
  .pl-xs-20p {
    padding-left: 20%;
  }
  .pl-xs-30p {
    padding-left: 30%;
  }
  .pl-xs-40p {
    padding-left: 40%;
  }
  .pl-xs-50p {
    padding-left: 50%;
  }
  .pl-xs-60p {
    padding-left: 60%;
  }
  .pl-xs-70p {
    padding-left: 70%;
  }
  .pl-xs-80p {
    padding-left: 80%;
  }
  .pl-xs-90p {
    padding-left: 90%;
  }

  .pt-xs-0 {
    padding-top: 0;
  }
  .pt-xs-1 {
    padding-top: calc(var(--basic-margin-unit) * 1);
  }
  .pt-xs-2 {
    padding-top: calc(var(--basic-margin-unit) * 2);
  }
  .pt-xs-3 {
    padding-top: calc(var(--basic-margin-unit) * 3);
  }
  .pt-xs-4 {
    padding-top: calc(var(--basic-margin-unit) * 4);
  }
  .pt-xs-5 {
    padding-top: calc(var(--basic-margin-unit) * 5);
  }
  .pt-xs-6 {
    padding-top: calc(var(--basic-margin-unit) * 6);
  }
  .pt-xs-7 {
    padding-top: calc(var(--basic-margin-unit) * 7);
  }
  .pt-xs-8 {
    padding-top: calc(var(--basic-margin-unit) * 8);
  }
  .pt-xs-9 {
    padding-top: calc(var(--basic-margin-unit) * 9);
  }
  .pt-xs-10 {
    padding-top: calc(var(--basic-margin-unit) * 10);
  }

  .pr-xs-0 {
    padding-right: 0;
  }
  .pr-xs-1 {
    padding-right: calc(var(--basic-margin-unit) * 1);
  }
  .pr-xs-2 {
    padding-right: calc(var(--basic-margin-unit) * 2);
  }
  .pr-xs-3 {
    padding-right: calc(var(--basic-margin-unit) * 3);
  }
  .pr-xs-4 {
    padding-right: calc(var(--basic-margin-unit) * 4);
  }
  .pr-xs-5 {
    padding-right: calc(var(--basic-margin-unit) * 5);
  }
  .pr-xs-6 {
    padding-right: calc(var(--basic-margin-unit) * 6);
  }
  .pr-xs-7 {
    padding-right: calc(var(--basic-margin-unit) * 7);
  }
  .pr-xs-8 {
    padding-right: calc(var(--basic-margin-unit) * 8);
  }
  .pr-xs-9 {
    padding-right: calc(var(--basic-margin-unit) * 9);
  }
  .pr-xs-10 {
    padding-right: calc(var(--basic-margin-unit) * 10);
  }

  .pl-xs-0 {
    padding-left: 0;
  }
  .pl-xs-1 {
    padding-left: calc(var(--basic-margin-unit) * 1);
  }
  .pl-xs-2 {
    padding-left: calc(var(--basic-margin-unit) * 2);
  }
  .pl-xs-3 {
    padding-left: calc(var(--basic-margin-unit) * 3);
  }
  .pl-xs-4 {
    padding-left: calc(var(--basic-margin-unit) * 4);
  }
  .pl-xs-5 {
    padding-left: calc(var(--basic-margin-unit) * 5);
  }
  .pl-xs-6 {
    padding-left: calc(var(--basic-margin-unit) * 6);
  }
  .pl-xs-7 {
    padding-left: calc(var(--basic-margin-unit) * 7);
  }
  .pl-xs-8 {
    padding-left: calc(var(--basic-margin-unit) * 8);
  }
  .pl-xs-9 {
    padding-left: calc(var(--basic-margin-unit) * 9);
  }
  .pl-xs-10 {
    padding-left: calc(var(--basic-margin-unit) * 10);
  }

  .pb-xs-0 {
    padding-bottom: 0;
  }
  .pb-xs-1 {
    padding-bottom: calc(var(--basic-margin-unit) * 1);
  }
  .pb-xs-2 {
    padding-bottom: calc(var(--basic-margin-unit) * 2);
  }
  .pb-xs-3 {
    padding-bottom: calc(var(--basic-margin-unit) * 3);
  }
  .pb-xs-4 {
    padding-bottom: calc(var(--basic-margin-unit) * 4);
  }
  .pb-xs-5 {
    padding-bottom: calc(var(--basic-margin-unit) * 5);
  }
  .pb-xs-6 {
    padding-bottom: calc(var(--basic-margin-unit) * 6);
  }
  .pb-xs-7 {
    padding-bottom: calc(var(--basic-margin-unit) * 7);
  }
  .pb-xs-8 {
    padding-bottom: calc(var(--basic-margin-unit) * 8);
  }
  .pb-xs-9 {
    padding-bottom: calc(var(--basic-margin-unit) * 9);
  }
  .pb-xs-10 {
    padding-bottom: calc(var(--basic-margin-unit) * 10);
  }

  .m-xs-0 {
    margin: 0;
  }
  .m-xs-1 {
    margin: calc(var(--basic-margin-unit) * 1);
  }
  .m-xs-2 {
    margin: calc(var(--basic-margin-unit) * 2);
  }
  .m-xs-3 {
    margin: calc(var(--basic-margin-unit) * 3);
  }
  .m-xs-4 {
    margin: calc(var(--basic-margin-unit) * 4);
  }
  .m-xs-5 {
    margin: calc(var(--basic-margin-unit) * 5);
  }
  .m-xs-6 {
    margin: calc(var(--basic-margin-unit) * 6);
  }
  .m-xs-7 {
    margin: calc(var(--basic-margin-unit) * 7);
  }
  .m-xs-8 {
    margin: calc(var(--basic-margin-unit) * 8);
  }
  .m-xs-9 {
    margin: calc(var(--basic-margin-unit) * 9);
  }
  .m-xs-10 {
    margin: calc(var(--basic-margin-unit) * 10);
  }

  .mr-xs-10p {
    margin-right: 10%;
  }
  .mr-xs-20p {
    margin-right: 20%;
  }
  .mr-xs-30p {
    margin-right: 30%;
  }
  .mr-xs-40p {
    margin-right: 40%;
  }
  .mr-xs-50p {
    margin-right: 50%;
  }
  .mr-xs-60p {
    margin-right: 60%;
  }
  .mr-xs-70p {
    margin-right: 70%;
  }
  .mr-xs-80p {
    margin-right: 80%;
  }
  .mr-xs-90p {
    margin-right: 90%;
  }

  .ml-xs-10p {
    margin-left: 10%;
  }
  .ml-xs-20p {
    margin-left: 20%;
  }
  .ml-xs-30p {
    margin-left: 30%;
  }
  .ml-xs-40p {
    margin-left: 40%;
  }
  .ml-xs-50p {
    margin-left: 50%;
  }
  .ml-xs-60p {
    margin-left: 60%;
  }
  .ml-xs-70p {
    margin-left: 70%;
  }
  .ml-xs-80p {
    margin-left: 80%;
  }
  .ml-xs-90p {
    margin-left: 90%;
  }

  .mt-xs-0 {
    margin-top: 0;
  }
  .mt-xs-1 {
    margin-top: calc(var(--basic-margin-unit) * 1);
  }
  .mt-xs-2 {
    margin-top: calc(var(--basic-margin-unit) * 2);
  }
  .mt-xs-3 {
    margin-top: calc(var(--basic-margin-unit) * 3);
  }
  .mt-xs-4 {
    margin-top: calc(var(--basic-margin-unit) * 4);
  }
  .mt-xs-5 {
    margin-top: calc(var(--basic-margin-unit) * 5);
  }
  .mt-xs-6 {
    margin-top: calc(var(--basic-margin-unit) * 6);
  }
  .mt-xs-7 {
    margin-top: calc(var(--basic-margin-unit) * 7);
  }
  .mt-xs-8 {
    margin-top: calc(var(--basic-margin-unit) * 8);
  }
  .mt-xs-9 {
    margin-top: calc(var(--basic-margin-unit) * 9);
  }
  .mt-xs-10 {
    margin-top: calc(var(--basic-margin-unit) * 10);
  }

  .mr-xs-0 {
    margin-right: 0;
  }
  .mr-xs-1 {
    margin-right: calc(var(--basic-margin-unit) * 1);
  }
  .mr-xs-2 {
    margin-right: calc(var(--basic-margin-unit) * 2);
  }
  .mr-xs-3 {
    margin-right: calc(var(--basic-margin-unit) * 3);
  }
  .mr-xs-4 {
    margin-right: calc(var(--basic-margin-unit) * 4);
  }
  .mr-xs-5 {
    margin-right: calc(var(--basic-margin-unit) * 5);
  }
  .mr-xs-6 {
    margin-right: calc(var(--basic-margin-unit) * 6);
  }
  .mr-xs-7 {
    margin-right: calc(var(--basic-margin-unit) * 7);
  }
  .mr-xs-8 {
    margin-right: calc(var(--basic-margin-unit) * 8);
  }
  .mr-xs-9 {
    margin-right: calc(var(--basic-margin-unit) * 9);
  }
  .mr-xs-10 {
    margin-right: calc(var(--basic-margin-unit) * 10);
  }

  .ml-xs-0 {
    margin-left: 0;
  }
  .ml-xs-1 {
    margin-left: calc(var(--basic-margin-unit) * 1);
  }
  .ml-xs-2 {
    margin-left: calc(var(--basic-margin-unit) * 2);
  }
  .ml-xs-3 {
    margin-left: calc(var(--basic-margin-unit) * 3);
  }
  .ml-xs-4 {
    margin-left: calc(var(--basic-margin-unit) * 4);
  }
  .ml-xs-5 {
    margin-left: calc(var(--basic-margin-unit) * 5);
  }
  .ml-xs-6 {
    margin-left: calc(var(--basic-margin-unit) * 6);
  }
  .ml-xs-7 {
    margin-left: calc(var(--basic-margin-unit) * 7);
  }
  .ml-xs-8 {
    margin-left: calc(var(--basic-margin-unit) * 8);
  }
  .ml-xs-9 {
    margin-left: calc(var(--basic-margin-unit) * 9);
  }
  .ml-xs-10 {
    margin-left: calc(var(--basic-margin-unit) * 10);
  }

  .mb-xs-0 {
    margin-bottom: 0;
  }
  .mb-xs-1 {
    margin-bottom: calc(var(--basic-margin-unit) * 1);
  }
  .mb-xs-2 {
    margin-bottom: calc(var(--basic-margin-unit) * 2);
  }
  .mb-xs-3 {
    margin-bottom: calc(var(--basic-margin-unit) * 3);
  }
  .mb-xs-4 {
    margin-bottom: calc(var(--basic-margin-unit) * 4);
  }
  .mb-xs-5 {
    margin-bottom: calc(var(--basic-margin-unit) * 5);
  }
  .mb-xs-6 {
    margin-bottom: calc(var(--basic-margin-unit) * 6);
  }
  .mb-xs-7 {
    margin-bottom: calc(var(--basic-margin-unit) * 7);
  }
  .mb-xs-8 {
    margin-bottom: calc(var(--basic-margin-unit) * 8);
  }
  .mb-xs-9 {
    margin-bottom: calc(var(--basic-margin-unit) * 9);
  }
  .mb-xs-10 {
    margin-bottom: calc(var(--basic-margin-unit) * 10);
  }
}
